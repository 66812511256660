










import { defineComponent } from '@vue/composition-api';

import { QuestionData, useQuestionRepository } from '@/training/domains';

import Crud from '../components/organisms/Crud.vue';

export default defineComponent({
  name: 'DevQuestionsPage',
  components: {
    Crud,
  },
  props: {},
  setup() {
    const repository = useQuestionRepository();

    const defaultEntity: QuestionData = {
      groupId: 'grId00',
      comments: [
        {
          body: 'comment0',
          createdBy: '',
          groupId: 'grId00',
        },
      ],
      resolved: false,
      title: 'question00',
      createdBy: '',
      assignees: [],
    };

    async function fetch() {
      return repository.findQuestions({
        groupId: 'grId00',
      });
    }

    async function add(entity: QuestionData): Promise<void> {
      await repository.save(entity);
    }

    async function remove(id: string) {
      await repository.remove(id);
    }

    function entitySummary(entity: QuestionData) {
      return `${entity.id} ${entity.title}`;
    }

    return {
      defaultEntity,
      fetch,
      add,
      remove,
      entitySummary,
    };
  },
});
