



























import { defineComponent, ref } from '@vue/composition-api';

import MyApp from '@/base/app/components/MyApp.vue';
import { useAuthService } from '@/base/domains';
import { createLogger } from '@/utils/log';
import { useRoute, useRouter } from '@/utils/VueUtils';

export default defineComponent({
  name: 'DevSignUpPage',
  components: { MyApp },
  props: {},
  setup() {
    const authService = useAuthService();
    const route = useRoute();
    const router = useRouter();

    const logger = createLogger({ boundedContext: 'base', name: 'ConfirmSignUpPage' });

    logger.info({ message: 'route', query: route.query });

    const userId = ref(route.query.userId as string);
    const code = ref('');
    const errorMessages = ['正しいコードではありません'];
    const error = ref(false);
    async function confirm(): Promise<void> {
      try {
        await authService.confirmSignUp({
          userId: userId.value,
          code: code.value,
        });
        router.push({ name: 'home' });
      } catch (e) {
        error.value = true;
      }
    }

    async function resendToken() {
      try {
        await authService.resendSignUp(userId.value);
      } catch (e) {
        // do nothing
      }
    }

    return {
      code,
      error,
      confirm,
      errorMessages,
      resendToken,
    };
  },
});
