










import { defineComponent } from '@vue/composition-api';

import { GroupTrainingData, useGroupTrainingRepository } from '@/training/domains';

import Crud from '../components/organisms/Crud.vue';

export default defineComponent({
  name: 'DevGroupTrainingsPage',
  components: {
    Crud,
  },
  props: {},
  setup() {
    const groupTrainingRepository = useGroupTrainingRepository();

    const defaultEntity: Omit<GroupTrainingData, 'id'> = {
      groupId: '',
    };

    async function fetch() {
      return groupTrainingRepository.findTenantGroupTrainings();
    }

    async function add(entity: GroupTrainingData): Promise<void> {
      await groupTrainingRepository.save(entity);
    }

    async function remove(id: string) {
      await groupTrainingRepository.remove(id);
    }

    function entitySummary(entity: GroupTrainingData) {
      return `${entity.id} ${entity.groupId}`;
    }

    return {
      defaultEntity,
      fetch,
      add,
      remove,
      entitySummary,
    };
  },
});
