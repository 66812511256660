










import { defineComponent } from '@vue/composition-api';
import moment from 'moment';

import { ScheduleData, useScheduleRepository } from '@/training/domains';

import Crud from '../components/organisms/Crud.vue';

export default defineComponent({
  name: 'DevScheudlesPage',
  components: {
    Crud,
  },
  props: {},
  setup() {
    const repository = useScheduleRepository();

    const defaultEntity = {
      groupId: '',
      start: moment().toISOString(),
      end: moment().add(1, 'hours').toISOString(),
      title: 'title',
      body: 'body',
    };

    async function fetch() {
      return repository.findRecently({ groupId: 'gid' });
    }

    async function add(entity: ScheduleData): Promise<void> {
      await repository.save(entity);
    }

    async function remove(id: string) {
      await repository.remove(id);
    }

    function entitySummary(group: ScheduleData) {
      return `${group.id}`;
    }

    return {
      defaultEntity,
      fetch,
      add,
      remove,
      entitySummary,
    };
  },
});
