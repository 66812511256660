




































import { defineComponent, ref } from '@vue/composition-api';

import MyApp from '@/base/app/components/MyApp.vue';
import { useAuthService } from '@/base/domains';

export default defineComponent({
  name: 'DevChangePasswordPage',
  components: { MyApp },
  props: {},
  setup() {
    const authService = useAuthService();

    const code = ref('');
    const email = ref('');
    const password = ref('');
    const errorMessages = ['正しいコードではありません'];
    const error = ref(false);
    async function changePassword(): Promise<void> {
      try {
        await authService.changePassword({
          email: email.value,
          code: code.value,
          password: password.value,
        });
      } catch (e) {
        error.value = true;
      }
    }

    async function resendToken() {
      try {
        await authService.forgotPassword(email.value);
      } catch (e) {
        // do nothing
      }
    }

    return {
      code,
      email,
      password,
      error,
      changePassword,
      errorMessages,
      resendToken,
    };
  },
});
