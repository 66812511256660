





















































import { defineComponent, onMounted, ref } from '@vue/composition-api';

import { ContentReference, useContentRepository } from '@/contents/domains';

export default defineComponent({
  name: 'DevContentsPage',
  components: {},
  props: {},
  setup() {
    const contentRepository = useContentRepository();

    function prettyStringify(obj: {}) {
      return JSON.stringify(obj, null, 2);
    }

    const defaultContent = {
      type: 'text',
      code: 'content-code-0',
      name: 'content 0',
      version: 1,
      requiredTime: 10,
      content: {
        body: 'TEST',
        bodyVersion: 1,
      },
      workbook: {
        problems: [
          {
            index: 0,
            type: 'choice',
            multiple: false,
            options: [
              { index: 0, text: 'option 0' },
              { index: 1, text: 'option 1' },
            ],
            answer: [0],
            body: 'BODY',
          },
        ],
      },
    };
    const json = ref('');
    const contents = ref<Array<ContentReference>>([]);
    const dialog = ref(false);
    const error = ref(false);

    async function loadContents() {
      contents.value = await contentRepository.findTenantContents();
    }

    function showDialog(args?: { id: string }) {
      const id = args?.id;
      const content: {} = id
        ? contents.value.find((c) => c.id === id) || defaultContent
        : defaultContent;
      json.value = prettyStringify(content);
      dialog.value = true;
    }

    onMounted(() => {
      loadContents();
    });

    return {
      json,
      loadContents,
      prettyStringify,
      contents,
      dialog,
      showDialog,
      error,
    };
  },
});
