










import { defineComponent } from '@vue/composition-api';

import { GroupData, useGroupRepository } from '@/base/domains';

import Crud from '../components/organisms/Crud.vue';

export default defineComponent({
  name: 'DevGroupsPage',
  components: {
    Crud,
  },
  props: {},
  setup() {
    const groupRepository = useGroupRepository();

    const defaultEntity: Omit<GroupData, 'id'> = {
      name: 'group',
      extensionConfigs: [
        {
          version: '1.0.0',
          name: 'slack',
        },
      ],
      users: [{ id: 'uid0', role: 'trainer', removed: false }],
      limitations: { question: [], schedule: [], questionnaire: [] },
      enabled: true,
    };

    async function fetch() {
      return groupRepository.findTenantGroups();
    }

    async function add(entity: GroupData): Promise<void> {
      await groupRepository.save(entity);
    }

    async function remove(id: string) {
      await groupRepository.remove(id);
    }

    function entitySummary(group: GroupData) {
      return `${group.id} ${group.name}`;
    }

    return {
      defaultEntity,
      fetch,
      add,
      remove,
      entitySummary,
    };
  },
});
