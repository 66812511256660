

























































import { defineComponent, ref } from '@vue/composition-api';

import { useAuthService } from '@/base/domains';
import { useRouter } from '@/utils/VueUtils';

export default defineComponent({
  name: 'DevSignUpPage',
  components: {},
  props: {},
  setup() {
    const authService = useAuthService();
    const router = useRouter();

    const tenantCode = ref('');
    const tenantName = ref('');
    const email = ref('');
    const password = ref('');
    const userName = ref('');
    const userCode = ref('');
    const errorMessages = ['サインアップできませんでした。'];
    const error = ref(false);
    async function signUp(): Promise<void> {
      try {
        // eslint-disable-next-line no-nested-ternary
        const req = tenantName.value
          ? {
              tenantCode: tenantCode.value,
              tenantName: tenantName.value,
              password: password.value,
              userName: userName.value,
              userCode: userCode.value.length > 0 ? userCode.value : undefined,
              email: email.value,
            }
          : userCode.value
          ? {
              tenantCode: tenantCode.value,
              password: password.value,
              userName: userName.value,
              userCode: userCode.value,
            }
          : {
              tenantCode: tenantCode.value,
              email: email.value,
              password: password.value,
              userName: userName.value,
            };
        const userId = await authService.signUp(req);
        router.push({ name: 'devConfirmSignUp', query: { userId } });
      } catch (e) {
        error.value = true;
      }
    }

    return {
      userName,
      email,
      password,
      error,
      signUp,
      errorMessages,
      userCode,
      tenantCode,
      tenantName,
    };
  },
});
