







































































import { defineComponent, onMounted, ref } from '@vue/composition-api';

import { Course } from '@/base/domains';
import { CourseData, useCourseRepository } from '@/contents/domains';
import { localDateTimeNow } from '@/utils/DateUtils';

export default defineComponent({
  name: 'DevCoursesPage',
  components: {},
  props: {},
  setup() {
    const courseRepository = useCourseRepository();

    function prettyStringify(obj: {}) {
      return JSON.stringify(obj, null, 2);
    }

    const defaultCourse: Omit<CourseData, 'id'> = {
      name: 'course',
      contents: [
        {
          type: 'text',
          name: 'content0',
          id: 'cnId0',
          requiredTime: 10,
        },
      ],
      version: 1,
      confirmedAt: localDateTimeNow(),
    };
    const json = ref('');
    const courses = ref<Array<Course>>([]);
    const dialog = ref(false);
    const error = ref(false);

    async function loadCourses() {
      courses.value = await courseRepository.findTenantCourses();
    }

    async function registerCourse(): Promise<void> {
      try {
        const course = JSON.parse(json.value) as CourseData;
        await courseRepository.save(course);
        await loadCourses();
      } catch (e) {
        error.value = true;
      } finally {
        dialog.value = false;
      }
    }

    async function deleteCourse(id: string) {
      try {
        await courseRepository.remove({ id, version: 1 });
        await loadCourses();
      } catch (e) {
        error.value = true;
      } finally {
        dialog.value = false;
      }
    }

    function showDialog(args?: { id: string }) {
      const id = args?.id;
      const course: {} = id
        ? courses.value.find((c) => c.id === id) || defaultCourse
        : defaultCourse;
      json.value = prettyStringify(course);
      dialog.value = true;
    }

    onMounted(async () => {
      loadCourses();
    });

    return {
      json,
      registerCourse,
      deleteCourse,
      loadCourses,
      prettyStringify,
      courses,
      dialog,
      showDialog,
      error,
    };
  },
});
