











import { defineComponent } from '@vue/composition-api';

import { NotificationData, useNotificationRepository } from '@/base/domains';

import Crud from '../components/organisms/Crud.vue';

export default defineComponent({
  name: 'DevNotificationsPage',
  components: {
    Crud,
  },
  props: {},
  setup() {
    const notificationRepository = useNotificationRepository();

    const defaultEntity: Omit<NotificationData, 'id' | 'createdAt' | 'type'> = {
      payload: { foo: 'a', bar: 'b' },
      userId: '',
      read: 'read',
    };

    async function fetch() {
      // return isDefined(globalStore.user.value)
      //   ? notificationRepository.findByUserId(globalStore.user.value.id)
      //   : Promise.resolve([]);
      return notificationRepository.findByUserId('5078af95-edd1-4567-a3f4-0f26e0ce3043');
    }

    async function add(entity: NotificationData): Promise<void> {
      await notificationRepository.save(entity);
    }

    async function remove(id: string) {
      await notificationRepository.remove(id);
    }

    function entitySummary(entity: NotificationData) {
      return `${entity.id} ${entity.type.toString()}`;
    }

    return {
      defaultEntity,
      fetch,
      add,
      remove,
      entitySummary,
    };
  },
});
