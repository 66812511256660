

























import { computed, defineComponent, ref } from '@vue/composition-api';
import { Auth } from 'aws-amplify';

import MyApp from '@/base/app/components/MyApp.vue';
import { useGlobalStore } from '@/base/app/store';
import { useGetMobileHomeContents } from '@/home/usecases';
import { useLocalStorage } from '@/utils/VueUtils';

export default defineComponent({
  name: 'DevIndexPage',
  components: { MyApp },
  props: {},
  setup() {
    const globalStore = useGlobalStore();
    const amplifyUser = ref();
    const list = computed(() => {
      const l: Array<{ key: string; value: unknown }> = [];
      l.push({ key: 'group', value: globalStore.group });
      l.push({ key: 'user', value: globalStore.user });
      l.push({ key: 'amplify.user', value: amplifyUser.value });
      return l;
    });

    const x = useLocalStorage('devX', 1);
    const u = useGetMobileHomeContents();
    globalStore.onTenantDataFirstFetched(async () => {
      amplifyUser.value = await Auth.currentAuthenticatedUser();
      const res = await u.execute({});
      console.log({
        res,
      });
    });

    const { signOut } = globalStore;

    return {
      list,
      signOut,
      countUpX: () => {
        x.value += 1;
        console.log({
          message: 'count up x',
          x: x.value,
        });
      },
    };
  },
});
