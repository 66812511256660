





































































import { defineComponent, onMounted, PropType, ref } from '@vue/composition-api';

import MyApp from '@/base/app/components/MyApp.vue';
import { createLogger } from '@/utils/log';

type Entity = { id: string };
type Props = {
  title: string;
  fetch: () => Promise<Array<Entity>>;
  add: (entity: Entity) => Promise<void>;
  remove: (id: string) => Promise<void>;
  entitySummary: (entity: Entity) => string;
  defaultEntity: {};
};

export default defineComponent({
  name: 'DevCrud',
  components: { MyApp },
  props: {
    title: {
      type: String,
      required: true,
    },
    fetch: {
      type: Function as PropType<() => Promise<Array<Entity>>>,
      required: true,
    },
    add: {
      type: Function as PropType<(entity: Entity) => Promise<void>>,
      required: true,
    },
    remove: {
      type: Function as PropType<(id: string) => Promise<void>>,
      required: true,
    },
    defaultEntity: {
      type: Object,
      default: () => ({}),
    },
    entitySummary: {
      type: Function as PropType<(entity: Entity) => string>,
      default: (entity: Entity) => entity.id,
    },
  },
  setup(props: Props) {
    const logger = createLogger({ boundedContext: 'dev', name: 'Crud' });

    function prettyStringify(obj: {}) {
      return JSON.stringify(obj, null, 2);
    }
    const json = ref('');
    const entities = ref<Array<Entity>>([]);
    const dialog = ref(false);
    const error = ref(false);

    async function fetchEntities() {
      entities.value = await props.fetch();
    }

    async function regsiterEntity(): Promise<void> {
      try {
        const entity = JSON.parse(json.value) as Entity;
        await props.add(entity);
        await fetchEntities();
      } catch (e) {
        logger.error({
          message: 'an error occurred at registering a entity',
          e,
        });
        error.value = true;
      } finally {
        dialog.value = false;
      }
    }

    async function removeEntity(id: string) {
      try {
        await props.remove(id);
        await fetchEntities();
      } catch (e) {
        logger.error({
          message: 'an error occurred at removing a entity',
          e,
        });
        error.value = true;
      } finally {
        dialog.value = false;
      }
    }

    function showDialog(args?: { id: string }) {
      const id = args?.id;
      const group: {} = id
        ? entities.value.find((c) => c.id === id) || props.defaultEntity
        : props.defaultEntity;
      json.value = prettyStringify(group);
      dialog.value = true;
    }

    onMounted(async () => {
      await fetchEntities();
    });

    return {
      json,
      regsiterEntity,
      removeEntity,
      prettyStringify,
      entities,
      dialog,
      showDialog,
      error,
    };
  },
});
