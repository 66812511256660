


























import { defineComponent, ref } from '@vue/composition-api';

import MyApp from '@/base/app/components/MyApp.vue';
import { useFileStorage } from '@/base/domains';
import { createLogger } from '@/utils/log';

const logger = createLogger({ boundedContext: 'dev', name: 'FileStoragePage' });

export default defineComponent({
  name: 'DevFileStoragePage',
  components: { MyApp },
  props: {},
  setup() {
    const fileStorage = useFileStorage();
    const file = ref<File>();
    const url = ref<string>();

    function change(f: File) {
      file.value = f;
    }

    async function upload() {
      if (file.value) {
        const f = file.value;
        url.value = await fileStorage.upload({
          filename: f.name,
          file: f,
          onProgress: (progress) => {
            logger.info({
              message: 'onProgress',
              progress,
            });
          },
        });
      }
    }

    async function setCookie() {
      await fileStorage.setSignedCookie();
    }

    return {
      change,
      upload,
      setCookie,
      url,
    };
  },
});
