










import { defineComponent } from '@vue/composition-api';

import { UserData, useUserRepository } from '@/base/domains';

import Crud from '../components/organisms/Crud.vue';

export default defineComponent({
  name: 'DevUsersPage',
  components: {
    Crud,
  },
  props: {},
  setup() {
    const userRepository = useUserRepository();

    const defaultEntity: Omit<UserData, 'id'> = {
      name: 'Test',
      extensionConfigs: [
        {
          version: '1.0.0',
          name: 'slack',
        },
      ],
      email: 'test@example.com',
      code: 'test',
      role: 'general',
      tenantCode: 'dev',
      locale: 'ja',
      tags: [],
      status: 'active',
      enabled: true,
      signedInAtLeastOnce: true,
      confirmedTermsOfServiceVersions: {},
    };

    async function fetch() {
      return userRepository.findTenantUsers();
    }

    async function add(entity: UserData): Promise<void> {
      await userRepository.save(entity);
    }

    async function remove(id: string) {
      await userRepository.remove(id);
    }

    function entitySummary(entity: UserData) {
      return `${entity.id} ${entity.name}`;
    }

    return {
      defaultEntity,
      fetch,
      add,
      remove,
      entitySummary,
    };
  },
});
